import React from "react";

export default function Footer() {
  return (
    <footer className="section footer">
      <div className="container w-container">
        <div className="footer-menu">
          <div href="#" className="footer-brand">
            <img
              src="https://uploads-ssl.webflow.com/60783e99067b79054634260c/6079c082783403079958c704_logo_white.svg"
              loading="lazy"
              width="122"
              alt=""
              className="image-3"
            />
            <p class="paragraph-2">
              Learn.ink is a digital learning tool created by the team at
              Farm.ink. Our mission is to make it as simple as possible for you
              to get your training content online and in the hands of your
              learners. We build all of our technology in house and would love
              to chat to you about how Learn.ink can support the work
              you&#x27;re doing. Get in touch!
            </p>
          </div>
          <div className="footer-column">
            <h3 className="footer-label">Information</h3>
            <ul className="w-list-unstyled">
              <li>
                <a href="https://learn.ink/about" className="footer-link">
                  About
                </a>
              </li>
              <li>
                <a href="https://learn.ink/privacy" className="footer-link">
                  Privacy policy
                </a>
              </li>
              <li>
                <a href="https://learn.ink/terms" className="footer-link">
                  Terms of service
                </a>
              </li>
            </ul>
          </div>
          <div className="footer-column">
            <h3 className="footer-label">Resources</h3>
            <ul className="w-list-unstyled">
              <li>
                <a
                  href="https://learn.ink/case-studies/all"
                  className="footer-link"
                >
                  Case studies
                </a>
              </li>
              <li>
                <a href="https://learn.ink/blog" className="footer-link">
                  Blog
                </a>
              </li>
              <li>
                <a href="https://docs.learn.ink" className="footer-link">
                  Help centre
                </a>
              </li>
            </ul>
          </div>
          <div className="footer-column">
            <h3 className="footer-label">Contact us</h3>
            <ul className="w-list-unstyled">
              <li>
                <a
                  href="mailto:info@farm.ink?subject=Hey%20%F0%9F%91%8B"
                  className="footer-link"
                >
                  info@farm.ink
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="div-block-7">
          <p className="paragraph-2">Copyright Farm.ink Ltd</p>
        </div>
      </div>
    </footer>
  );
}
