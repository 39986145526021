import React from "react";
import styled from "styled-components";

const Container = styled.div`
  width: 100%;
  margin: 0 auto;
  text-align: left;
  margin-bottom: 24px;
  margin-top: 24px;
`;
const VidIFrame = styled.iframe`
  width: 100%;
  min-height: 400px;
  margin: 0 auto;
  @media only screen and (max-width: 501px) {
    width: 100%;
    min-height: 200px;
  }
`;

export default function VideoPlayer({ url }) {
  return (
    <Container>
      <VidIFrame
        src={url}
        title="docs-video"
        frameBorder="0"
        webkitallowfullscreen="true"
        mozallowfullscreen="true"
        allowFullScreen
      ></VidIFrame>
    </Container>
  );
}
