import "gatsby-theme-apollo-docs/src/prism.less";
import "prismjs/plugins/line-numbers/prism-line-numbers.css";
import DocsetSwitcher from "gatsby-theme-apollo-docs/src/components/docset-switcher";
import Header from "gatsby-theme-apollo-docs/src/components/header";
import HeaderButton from "./header-button";
import Footer from "../../components/Footer";
import PropTypes from "prop-types";
import React, { createContext, useMemo, useRef, useState } from "react";
//import Search from 'gatsby-theme-apollo-docs/src/components/search';
import styled from "@emotion/styled";
import useLocalStorage from "react-use/lib/useLocalStorage";
import { Button } from "@apollo/space-kit/Button";
import {
  FlexWrapper,
  Layout,
  MenuButton,
  Sidebar,
  SidebarNav,
  breakpoints,
  colors,
  useResponsiveSidebar,
} from "gatsby-theme-apollo-core";
import { IconLayoutModule } from "@apollo/space-kit/icons/IconLayoutModule";
import { graphql, useStaticQuery } from "gatsby";
import { SelectedLanguageContext } from "gatsby-theme-apollo-docs/src/components/multi-code-block";
import { getSpectrumUrl } from "gatsby-theme-apollo-docs/src/utils";
import { size } from "polished";

const Main = styled.main({
  flexGrow: 1,
});

const ButtonWrapper = styled.div({
  flexGrow: 1,
});

const StyledButton = styled(Button)({
  width: "100%",
  ":not(:hover)": {
    backgroundColor: colors.background,
  },
});

const StyledIcon = styled(IconLayoutModule)(size(16), {
  marginLeft: "auto",
});

const MobileNav = styled.div({
  display: "none",
  [breakpoints.md]: {
    display: "flex",
    alignItems: "center",
    marginRight: 32,
    color: colors.text1,
  },
});

const HeaderInner = styled.span({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  marginBottom: 32,
});

const GA_EVENT_CATEGORY_SIDEBAR = "Sidebar";

function handleToggleAll(expanded) {
  if (typeof window.analytics !== "undefined") {
    window.analytics.track("Toggle all", {
      category: GA_EVENT_CATEGORY_SIDEBAR,
      label: expanded ? "expand" : "collapse",
    });
  }
}

function handleToggleCategory(title, expanded) {
  if (typeof window.analytics !== "undefined") {
    window.analytics.track("Toggle category", {
      category: GA_EVENT_CATEGORY_SIDEBAR,
      label: title,
      value: Number(expanded),
    });
  }
}

export const NavItemsContext = createContext();

export default function PageLayout(props) {
  const data = useStaticQuery(
    graphql`
      {
        site {
          siteMetadata {
            title
            siteName
          }
        }
      }
    `
  );

  const {
    sidebarRef,
    openSidebar,
    sidebarOpen,
    handleWrapperClick,
    handleSidebarNavLinkClick,
  } = useResponsiveSidebar();

  const buttonRef = useRef(null);
  const [menuOpen, setMenuOpen] = useState(false);
  const selectedLanguageState = useLocalStorage("docs-lang");

  function openMenu() {
    setMenuOpen(true);
  }

  function closeMenu() {
    setMenuOpen(false);
  }

  const { pathname } = props.location;
  const { siteName } = data.site.siteMetadata;
  const { sidebarContents } = props.pageContext;
  const {
    spectrumHandle,
    twitterHandle,
    youtubeUrl,
    navConfig = {},
    footerNavConfig,
    logoLink,
    algoliaApiKey,
    algoliaIndexName,
    menuTitle,
  } = props.pluginOptions;

  const navItems = useMemo(
    () =>
      Object.entries(navConfig).map(([title, navItem]) => ({
        ...navItem,
        title,
      })),
    [navConfig]
  );

  const hasNavItems = navItems.length > 0;
  const sidebarTitle = <span className="title-sidebar"></span>;

  return (
    <Layout>
      <React.Fragment>
        <FlexWrapper onClick={handleWrapperClick}>
          <Sidebar
            responsive
            className="sidebar"
            open={sidebarOpen}
            ref={sidebarRef}
            title={siteName}
            logoLink={logoLink}
          >
            <HeaderInner>
              {hasNavItems ? (
                <ButtonWrapper ref={buttonRef}>
                  <StyledButton
                    feel="flat"
                    color={colors.primary}
                    size="small"
                    onClick={openMenu}
                    style={{ display: "block" }}
                  >
                    {sidebarTitle}
                    <StyledIcon />
                  </StyledButton>
                </ButtonWrapper>
              ) : (
                sidebarTitle
              )}
            </HeaderInner>
            {sidebarContents && (
              <SidebarNav
                contents={sidebarContents}
                pathname={pathname}
                onToggleAll={handleToggleAll}
                onToggleCategory={handleToggleCategory}
                onLinkClick={handleSidebarNavLinkClick}
              />
            )}
          </Sidebar>
          <Main>
            <Header>
              <MobileNav>
                <MenuButton onClick={openSidebar} />
              </MobileNav>
              {algoliaApiKey && algoliaIndexName && null}
              <HeaderButton />
            </Header>
            <SelectedLanguageContext.Provider value={selectedLanguageState}>
              <NavItemsContext.Provider value={navItems}>
                {props.children}
              </NavItemsContext.Provider>
            </SelectedLanguageContext.Provider>
          </Main>
        </FlexWrapper>
      </React.Fragment>
      {hasNavItems && (
        <DocsetSwitcher
          siteName={menuTitle || siteName}
          spectrumUrl={spectrumHandle && getSpectrumUrl(spectrumHandle)}
          twitterUrl={twitterHandle && `https://twitter.com/${twitterHandle}`}
          youtubeUrl={youtubeUrl}
          navItems={navItems}
          footerNavConfig={footerNavConfig}
          open={menuOpen}
          buttonRef={buttonRef}
          onClose={closeMenu}
        />
      )}
      <Footer />
    </Layout>
  );
}

PageLayout.propTypes = {
  children: PropTypes.node.isRequired,
  location: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired,
  pluginOptions: PropTypes.object.isRequired,
};

/* ADD TO INSIDE SEARCH CONTAINER ONCE SEARCH IS WORKING

            <Search
              siteName={siteName}
              apiKey={algoliaApiKey}
              indexName={algoliaIndexName}
            />

            
            */
