import React from "react";
import { Helmet } from "react-helmet";
import Favicon from "../../assets/learnink_favicon.ico";

export default function SEO({ title, description }) {
  return (
    <Helmet>
      <html lang="en" amp />
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta property="og:type" content="article" />
      <link rel="icon" href={Favicon} />
      {/* other SEO tags (OpenGraph, Twitter, etc.) */}
    </Helmet>
  );
}
