import React from "react";
import styled from "@emotion/styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome } from "@fortawesome/free-solid-svg-icons";

const Wrapper = styled.div({
  display: "flex",
  marginTop: 60,
  marginBottom: -30,
});

const HomeHeader = styled.h6`
  color: rgb(47, 53, 63);
  font-weight: bold;
  font-size: 16px;
  letter-spacing: 0.1em;
  margin-left: 10px;
  margin-top: 0px;
  margin-bottom: 0px;
`;

export default function Home() {
  return (
    <Wrapper>
      <FontAwesomeIcon icon={faHome} />
      <HomeHeader>Home</HomeHeader>
    </Wrapper>
  );
}
